import { useEffect, useState, useCallback } from "react";

import { Header } from "semantic-ui-react";

import TargetRegion from "../../components/map/TargetRegion";
import geoJsonData from "../../gyeongsangbuk-do-filtered.json";
import "./AlbumModal.css";

const AlbumModal = ({
  isAlbumOn,
  setIsAlbumOn,
  data,
  solarlightList,
  solarheatList,
  allList,
}) => {
  const [currentRegionIndex, setCurrentRegionIndex] = useState(0);
  const regions = geoJsonData.features; // 지역 리스트 (feature 배열)
  const exitFullScreen = useCallback(() => {
    if (document.fullscreenElement) {
      document.exitFullscreen?.();
    }
    setIsAlbumOn(false);
  }, [setIsAlbumOn]);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        exitFullScreen();
      }
    };

    if (isAlbumOn) {
      document.documentElement.requestFullscreen?.();
      document.body.classList.add("blurred-background"); // 모달 실행 시 흐리게
      document.addEventListener("keydown", handleKeyDown);
    } else {
      exitFullScreen();
    }
    const interval = setInterval(() => {
      setCurrentRegionIndex((prevIndex) => (prevIndex + 1) % regions.length);
    }, 4000);

    return () => {
      clearInterval(interval);
      document.body.classList.remove("blurred-background");
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isAlbumOn, regions, exitFullScreen]);

  const selectedRegionForMap = regions[currentRegionIndex]; // 현재 선택된 지역
  const selectedRgeionName = selectedRegionForMap.properties.SIG_KOR_NM;
  const selectedRegionData = data[selectedRgeionName]; // 선택된 지역의 데이터

  const regionIdList = solarheatList
    .concat(solarlightList)
    .filter((item) => item.address?.title === selectedRgeionName)
    .map((item) => item.id);

  const totalOfTheRegion = allList
    .filter((data) => regionIdList.includes(data.id))
    .reduce((sum, data) => sum + parseFloat(data.today), 0);

  const formattedTotal = parseFloat((totalOfTheRegion / 1000).toFixed(2));

  if (!isAlbumOn) return null;

  return (
    <div className="flex flex-row w-full h-full album-modal">
      <div className="header-container">
        <Header as="span" className="main-header">
          경상북도
        </Header>
        <Header as="div" className="main-header-2">
          <div>신재생에너지 통합모니터링 시스템</div>
          <div className="subtitle">Renewable Energy Monitoring System</div>
        </Header>
      </div>
      <button className="close-btn" onClick={exitFullScreen}>
        ×
      </button>
      <div className="flex modal-content modal-content-region">
        <h1 className="modal-content-regionName">{selectedRgeionName}</h1>
        <p className="modal-content-region-OperationRatio">
          가동률:{" "}
          {selectedRegionData
            ? `${(
                (selectedRegionData.status.정상 / selectedRegionData.total) *
                100
              ).toFixed(2)}%`
            : "데이터 없음"}
        </p>
        <TargetRegion
          selectedRegion={selectedRegionForMap}
          today={formattedTotal}
        />
      </div>
      <div className="flex modal-content">
        <div className="modal-content-equip-title">설비 상태</div>
        {selectedRegionData ? (
          <div className="modal-content-equip-status">
            <p>총 개소: {selectedRegionData.total}</p>
            <p>정상 가동: {selectedRegionData.status.정상}</p>
            <p>경고: {selectedRegionData?.status?.경고 ?? 0}</p>
          </div>
        ) : (
          <p>데이터가 없습니다.</p>
        )}
      </div>
    </div>
  );
};

export default AlbumModal;
