"use client";

import * as d3 from "d3";
import { useEffect, useRef } from "react";

export default function TargetRegion({ selectedRegion, today }) {
  const svgRef = useRef(null);

  useEffect(() => {
    if (!selectedRegion) return; // 선택된 지역이 없으면 실행하지 않음

    const width = 500;
    const height = 500;

    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMidYMid meet");

    const projection = d3.geoMercator().fitExtent(
      [
        [20, 20], // 상단 좌측 여백
        [width - 30, height - 60], // 하단 우측 여백
      ],
      { type: "FeatureCollection", features: [selectedRegion] } // 단일 지역만 포함
    );

    const pathGenerator = d3.geoPath().projection(projection);

    const baseColor = "rgba(0, 160, 0, 0.3)";
    const borderColor = "darkgreen";

    svg.selectAll(".region").remove(); // 기존 데이터 삭제 후 다시 그림

    svg
      .selectAll(".region")
      .data([selectedRegion]) // 단일 지역만 렌더링
      .join("path")
      .attr("class", "region")
      .attr("d", pathGenerator)
      .attr("stroke", borderColor)
      .attr("fill", baseColor)
      .attr("stroke-width", 1.5);

    // 지도 중심 좌표 계산
    const centroid = pathGenerator.centroid(selectedRegion); // 중심 좌표 계산
    const centerX = centroid[0] || width / 2;
    const centerY = centroid[1] || height / 2;

    // today 값에 단위 추가 (예: "100 kW")
    // const todayText = today ?? "데이터 없음";
    const todayText =
      today !== null && today !== undefined ? `${today} kW` : "데이터 없음";

    // 기존 텍스트 제거 후 추가
    svg.selectAll(".region-text").remove();

    // 첫 번째 줄: 타이틀 (예: "발전량")
    svg
      .append("text")
      .attr("class", "region-text")
      .attr("x", centerX)
      .attr("y", centerY - 15) // 위쪽으로 약간 이동
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .attr("font-size", "18px")
      .attr("fill", "white")
      .attr("font-weight", "bold")
      .text("금일 발전/생산량"); // 고정된 타이틀

    // 두 번째 줄: 실제 today 값
    svg
      .append("text")
      .attr("class", "region-text")
      .attr("x", centerX)
      .attr("y", centerY + 15) // 아래쪽으로 약간 이동
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .attr("font-size", "22px")
      .attr("fill", "white")
      .attr("font-weight", "bold")
      .text(todayText || "데이터 없음"); // today 값이 없으면 "데이터 없음"
  }, [selectedRegion, today]);

  return (
    <div className="flex" style={{ backgroundColor: "transparent" }}>
      <svg ref={svgRef} className="flex" />
    </div>
  );
}
