import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighChartsMap from "highcharts/modules/map";
import geoJsonData from "../../gyeongsangbuk-do-filtered.json"; // GeoJSON 파일

HighChartsMap(Highcharts);

const Geo = ({ mapData }) => {
  useEffect(() => {
    // geoJsonData의 모든 지역명 추출
    // const featureNames = geoJsonData.features.map(
    //   (feature) => feature.properties.SIG_KOR_NM,
    // );

    // mapData의 모든 지역명 추출
    // const mapDataNames = Object.keys(mapData);

    // 1. mapData에 있지만 feature.properties에 없는 지역명 찾기
    // const inMapDataNotInFeatures = mapDataNames.filter(
    //   (name) => !featureNames.includes(name),
    // );

    // 2. feature.properties에 있지만 mapData에 없는 지역명 찾기
    // const inFeaturesNotInMapData = featureNames.filter(
    //   (name) => !mapDataNames.includes(name),
    // );

    const localData = geoJsonData.features.map((feature) => {
      const regionName = feature.properties.SIG_KOR_NM;
      const matchedRegionData = mapData[regionName];
      // const matchedRegionData =
      //   regionName === "포항시 북구" ? mapData["포항시"] : mapData[regionName];

      return {
        localIndex: feature.index,
        name: feature.properties.SIG_KOR_NM || "Unknown", // 이름을 정확히 추출
        regionData: matchedRegionData || {},
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "#dfebe0"], // 매우 연한 초록색 (상단)
            [1, "#cae3cc"], // 더 진한 초록색 (하단)
          ],
        }, // 각 구역에 그라데이션 적용
        shadow: {
          color: "#000000",
          offsetX: 2,
          offsetY: 2,
          opacity: 0.2,
          width: 3,
        }, // 각 구역에 그림자 적용
      };
    });

    Highcharts.mapChart("container", {
      accessibility: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      chart: {
        map: geoJsonData,
        backgroundColor: "#20242B",
      },
      title: {
        text: "",
        style: {
          color: "white",
        },
      },
      mapNavigation: {
        enabled: false,
        buttonOptions: {
          verticalAlign: "bottom",
        },
      },
      series: [
        {
          data: localData,
          keys: ["name", "regionData", "color"],
          joinBy: ["SIG_KOR_NM", "name"], // GeoJSON의 SIG_KOR_NM과 데이터의 name을 연결
          borderColor: "#006400", // 경계선 색상 (어두운 초록색 유지)
          borderWidth: 2, // 경계선 두께
          shadow: false, // 차트 전체에 그림자 효과 제거 (각 구역에만 적용)
          states: {
            hover: {
              color: "#02a602", // 마우스 오버 시 색상
            },
          },
          dataLabels: {
            enabled: true,
            useHTML: true,
            distance: 15,
            formatter: function () {
              const regionName = this.point.name;
              // const totalEquipment = this.point.regionData.total || 0;
              const totalEquip = this.point.regionData.total
                ? this.point.regionData.total
                : 0;
              // const status = this.point.regionData.status || {};
              const stat = this.point.regionData.status
                ? this.point.regionData.status
                : {};
              const operatingRate =
                totalEquip > 0
                  ? (((stat["정상"] || 0) / totalEquip) * 100).toFixed(1)
                  : 0;
              // return `<div style="text-align: center;"><span style="font-size: 15px;">${regionName}(${totalEquip})</span><br><div style={{marginTop:"3px;"}}><span style="margin-right: 15px;">가동 중: ${stat["정상"] || 0}</span></div></div>`;
              return `<div style="text-align: center; color: black;">
              <span style="font-size: 15px; text-shadow: -1px -1px 2px white, 1px -1px 2px white, -1px 1px 2px white, 1px 1px 2px white;">
                ${regionName}
              </span>
              <div style="margin-top: 3px;">
                <span style="font-size: 12px; text-shadow: -1px -1px 2px white, 1px -1px 2px white, -1px 1px 2px white, 1px 1px 2px white;">총 설치 개소: ${totalEquip}</span>
              </div>
              <div>
                <span style="font-size: 12px; text-shadow: -1px -1px 2px white, 1px -1px 2px white, -1px 1px 2px white, 1px 1px 2px white;">가동 중: ${
                  stat["정상"] || 0
                }</span>
              </div>
              </div>`;
              // <div>
              //   <span style="font-size: 10px; text-shadow: -1px -1px 2px white, 1px -1px 2px white, -1px 1px 2px white, 1px 1px 2px white;">가동률 : ${operatingRate}%</span>
              // </div>
            },
            style: {
              // textOutline: "1px",
              color: "black",
            },
            allowOverlap: true,
            // 수직 및 수평 가운데 정렬
            align: "center",
            verticalAlign: "middle",
            crop: false,
            overflow: "justify",
          },
          point: {
            events: {
              // mouseOver: function () {
              //   this.update(
              //     {
              //       dataLabels: {
              //         style: {
              //           fontSize: "24px",
              //           fontWeight: "600",
              //           color: "white",
              //         },
              //       },
              //     },
              //     false,
              //   );
              // },
              // mouseOut: function () {
              //   this.update({
              //     dataLabels: {
              //       style: { fontSize: "16px", fontWeight: "500" },
              //     },
              //   });
              // },
              // click: function () {
              //   this.series.chart.update({});
              // },
            },
          },
        },
      ],
      // responsive: {
      //   // 반응형 설정
      //   rules: [
      //     {
      //       condition: {
      //         maxWidth: 600, // 최대 너비 조건
      //       },
      //       chartOptions: {
      //         chart: {
      //           height: "60%", // 작은 화면일 때 높이를 60%로 조정
      //         },
      //       },
      //     },
      //   ],
      // },
    });
  }, [mapData]);

  return <div id="container" style={{ height: "90%", width: "100%" }}></div>;
};

export default Geo;
